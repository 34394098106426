import moment from 'moment-timezone';
import { formatDates } from './format-dates.function';
import StringHelper from './string.helper';
import { settingsModule } from '@/store/modules/settings.module';


export default class DateHelper {
    static readonly YearOne = '0001';
    static readonly DateFormat = 'DD/MM/YYYY';
    static readonly DateTimeFormat = 'DD/MM/YYYY hh:mm:ss A';
    static readonly BaseDateTimeFormat = 'YYYY-MM-DDThh:mm';
    static readonly DateTimeFormat24 = 'YYYY-MM-DDTHH:mm';
    static PlaceHolderDateFormats = {
        'YYYY-MM-DD': 'YYYY-MM-DD',
        'DD/MM/YYYY': 'DD/MM/YYYY',
        'MM/DD/YYYY': 'MM/DD/YYYY',
    }

    static PlaceHolderTimeFormats = {
        'HH:mm': 'hh:mm',
        'HH:mm:ss': 'hh:mm:ss',
        'hh:mm A': 'hh:mm',
        'hh:mm:ss A': 'hh:mm:ss',
    }

    static formatDate(option: string, format: string): string {
        if (option.includes('0001')) {
            return '';
        }
        const date = new Date(option);
        return DateHelper.format(date, format);
    }

    static isDateString(dateString: any): dateString is string {
        if (!dateString || typeof (dateString) !== 'string') {
            return false;
        }

        const validDateSeparators = ['-', '/', '.'];
        const validFormats = [
            DateHelper.DateFormat,
            DateHelper.DateTimeFormat,
            moment.ISO_8601,
            moment.RFC_2822
        ];

        const date = moment(dateString, validFormats, true);
        if (date.isValid()) {
            // note: make sure it was not false positive check on an integer value
            return validDateSeparators.some(separator => StringHelper.count(dateString, separator) === 2);
        }
        return false;
    }

    static isDateTimeString(dateString: any): dateString is string {
        if (!this.isDateString(dateString)) {
            return false;
        }
        const validDTimeSeparators = ['T', ' '];

        return validDTimeSeparators.some(separator => dateString.includes(separator) && dateString.split(separator).length === 2);
    }

    static toDate(date: string | Date | moment.Moment): Date {
        if (date instanceof Date) {
            return date;
        }

        if (moment.isMoment(date)) {
            return date.toDate();
        }

        if (this.isDateString(date)) {
            let hh = '00';
            let mm = '00';

            const timeSeparator = date.includes('T') ? 'T' :
                date.includes(' ') ? ' ' : null;
            if (timeSeparator) {
                const [dateStr, time] = date.split(timeSeparator);
                const timeStr = StringHelper.replaceAll(time, '%3A', ':');

                [hh, mm] = timeStr.split(':');
                date = dateStr;
            }

            let [yyyy, MM, dd] = date.split('-');

            if (dd.length > yyyy.length) {
                const year = dd;
                dd = yyyy;
                yyyy = year;
            }

            return new Date(
                parseInt(yyyy, 10),
                parseInt(MM, 10) - 1,
                parseInt(dd, 10),
                parseInt(hh, 10),
                parseInt(mm, 10)
            );
        }

        throw new Error('DateHelper: Bad input');
    }

    static format(date: string | Date | moment.Moment | null, format: string = DateHelper.DateFormat): string {
        if (!date) {
            return '';
        }
        if (!moment.isMoment(date)) {
            date = moment(date);
        }

        return date.format(format);
    }

    static formatUTC(date: string | Date | moment.Moment, format: string): string {
        if (!date) {
            return '';
        }
        const selectedRegionTime = moment.tz(date, settingsModule.getTimeStampSettings.region);
        const utcTime = selectedRegionTime.clone().tz('UTC');
        return utcTime.format(format);
    }


    static formatMany(format: string, ...data: any[]): any[] {
        return formatDates(format, data);
    }

    static getUtcOffset(region: string): number {
        return moment.tz(region).utcOffset() / 60;
    }

    static hasTimeComponent(dateString: string): boolean {
        const momentDate = moment(dateString);
        return momentDate.hours() === 0 && momentDate.minutes() === 0 && momentDate.seconds() === 0;
    }

    static convertDateTimeOnly(val: string): string {
        const { region } = settingsModule.getTimeStampSettings;

        if (DateHelper.isEmptyOrInvalidDate(val)) {
            return '';
        }

        return moment(`${val}Z`).tz(region).format();
    }

    static formatDateTime(val: string, type: string = 'dateTime'): string {
        const { date, time, region } = settingsModule.getTimeStampSettings;
        if (DateHelper.isEmptyOrInvalidDate(val)) {
            return '';
        }

        const datetime = val;
        const convertedDatetime = moment(`${datetime}Z`).tz(region);

        switch (type) {
            case 'dateTime':
                return convertedDatetime.format(`${date}  ${time}`);
            case 'time':
                return convertedDatetime.format(`${time}`);
            case 'date':
                return convertedDatetime.format(`${date}`);
            default:
                return convertedDatetime.format(`${date}`);
        }
    }

    static getDateFormatePlaceHolder(): string {
        return this.PlaceHolderDateFormats[settingsModule.getTimeStampSettings.date as keyof typeof this.PlaceHolderDateFormats];
    }

    static getDateTimeFormatePlaceHolder(): string {
        return `${this.PlaceHolderDateFormats[settingsModule.getTimeStampSettings.date as keyof typeof this.PlaceHolderDateFormats]} ${this.PlaceHolderTimeFormats[settingsModule.getTimeStampSettings.time as keyof typeof DateHelper.PlaceHolderTimeFormats]}`;
    }

    static isEmptyOrInvalidDate(val: string): boolean {
        return !val || val.includes(DateHelper.YearOne);
    }
}
