import Axios from 'axios';

import { $environment } from './environment.service';
import { $http } from './http.service';

import { IApportExtendedResponse, IApportResponse, IServerSideResponse } from '@/interfaces/api.interface';
import { IJobInstructionResponse } from '@/interfaces/web-api/job-instruction.interface';
import { IKpiData } from '@/interfaces/web-api/kpi.interfaces';
import { IAuthCredentials, IAuthResponse } from '@/interfaces/web-api/login.interface';
import { ICockpitSettingsResponse, IMenuSettings, IMenuSettingsResponse, IRouteConfigExtended, ISettings, ISystemSettingsResponse, ITerminalSettingsResponse } from '@/interfaces/settings.interface';
import { ISignalRResponse } from '@/interfaces/web-api/signalR.interface';
import { IActiveUsers, ILoadCarrier, IUser, IUserRole, IUserPasswordResetResponse, IUserSummary } from '@/interfaces/web-api/user.interface';
import { IMultiJobResponse, IMultiProcessResponse, IUnassignResponse } from '@/interfaces/web-api.interface';
import { ICauseCode, ICauseCodeHeader } from '@/interfaces/web-api/cause-codes.interface';
import { ILocationRoleGroup } from '@/interfaces/web-api/location-role-group.interface';
import { ILocationRole } from '@/interfaces/web-api/location-role.interface';
import { ProcessDefinitionCategory, ProcessDefinitionCategoryName } from '@/utilities/constants.enum';
import { WebApi } from '@/utilities/web-api.helper';
import { IPrinter, IPrinterDescription } from '@/interfaces/web-api/printer.interface';
import { ILocation, ILocationHeader } from '@/interfaces/web-api/location.interface';
import { IErpClassification } from '@/interfaces/web-api/erp-classification.interface';
import { ICommandTransactionStatus } from '@/interfaces/web-api/command.interface';
import { IViewCommandResult } from '@/interfaces/web-api/command.interface';
import { IInventoryAcceptResponse, IInventoryLineTask } from '@/interfaces/web-api/inventory.interface';
import { ITaskReservingStock } from '@/interfaces/web-api/task-reserving-stock.interface';
import { ApportClientId } from '@/views/settings/user-settings/user-settings.interface';
import { IMaterialBOM, IMaterialDescription } from '@/interfaces/web-api/material.interface';
import { IStockAttributeBase, IStockAttributeDescription, IStockAttributeEnumOptions } from '@/interfaces/web-api/stock-attributes.interface';
import { IDedicatedLocationCommand, IDedicatedLocationHeader } from '@/interfaces/web-api/dedicated-location.interface';
import { IOidcUri } from '@/interfaces/web-api/auth.interface';
import { IAssociateProduct, IProduct, ISupplier } from '../interfaces/web-api/supplier.interface';
import { IDraftSearchState, IPurchaseDraftResponse } from '../interfaces/web-api/draft.interface';
import { IApiEnum } from '@/interfaces/common.interface';
import { IMaterialSummaryReportData, IMaterialTransationsReportData, IMaterialTransationsRequestData, IReportData, ISlowMovingStockReportData, ISlowMovingStockRequestData, ReportType } from '@/interfaces/report.interface';
import { LookupResponse, LookupType } from '@/interfaces/web-api/lookup.interface';
import UrlHelper from '@/utilities/url.helper';
import { ISalesOrderState } from '@/views/pages/sales-order/create-sales-order/CreateSalesOrderPage.vue';
import { IOrderType, IDeliveryType, ICustomerInformation, IOrderDeliveryCommand } from '@/interfaces/web-api/sales-order.interface';
import { ILoadCarrierType } from '@/interfaces/web-api/load-carrier.interface';

// todo: update all 'any' to a proper interfaces
class WebApiService {
    get GET() {
        return {
            Authentication: AuthenticationMethodsGET,
            CauseCodes: CauseCodesMethodsGET,
            Command: CommandMethodsGET,
            Container: ContainerMethodsGET,
            DedicatedLocation: DedicatedLocationMethodsGET,
            Image: ImageMethodsGET,
            IngoingOrder: IngoingOrderMethodsGET,
            IntegrationMonitor: IntegrationMonitorMethodsGET,
            Inventory: InventoryMethodsGET,
            ErpClassification: ErpClassificationMethodsGET,
            Job: JobMethodsGET,
            LoadCarrierType: LoadCarrierTypeMethodsGET,
            Localization: LocalizationMethodsGET,
            Location: LocationMethodsGET,
            LocationRole: LocationRoleMethodsGET,
            LocationRoleGroup: LocationRoleGroupMethodsGET,
            Lookup: LookupMentodsGet,
            Material: MaterialMethodsGET,
            Printer: PrinterMethodsGET,
            ProcessDefinition: ProcessDefinitionMethodsGET,
            Report: ReportMethodsGET,
            Stock: StockMethodsGET,
            StockAttributeConfiguration: StockAttributeConfigurationMethodsGET,
            Storage: StorageMethodsGET,
            Task: TaskMethodsGET,
            User: UserMethodsGET,
            UserRole: UserRoleMethodsGET,
            UserSession: UserSessionMethodsGET,
            Supplier: SupplierMethodsGET,
            DraftSupplier: GetDraftSupplierMethodGET,
            DraftNumber: GetDraftNumberMethodGET,
            DraftLines: GetDraftLinesMethodGET,
            DraftLinesById: GetDraftLinesByIdMethodGET,
            OrderNumber: GetOrderNumberMethodGET,
            UnDraftedSupplierMaterial: UnDraftedSupplierMaterialMethodGET,
            SalesOrderDraft: SalesOrderDraftGET,
            SalesOrder: SalesOrderGET,
            OrderType: OrderTypeGET,
            DeliveryType: DeliveryTypeGET,
            CustomerInformation: CustomerInformationGET,
            SalesOrderDraftById: SalesOrderDraftByIdMethodGET,
            Settings: SettingsMethodsGET,
        };
    }
    get PATCH() {
        return {
            IngoingOrder: IngoingOrderMethodsPATCH
        };
    }
    get POST() {
        return {
            Authentication: AuthenticationMethodsPOST,
            DedicatedLocation: DedicatedLocationMethodsPOST,
            InstructionSet: InstructionSetMethodsPOST,
            Job: JobMethodsPOST,
            Location: LocationMethodsPOST,
            OutgoingOrder: OutgoingOrderMethodsPOST,
            Printer: PrinterMethodsPOST,
            ProductLineForSupplier: GetProductLineForSupplierMethodPOST,
            Seeding: SeedingMethodsPOST,
            Tools: ToolsMethodsPOST,
            UserChoice: UserChoiceMethodsPOST,
            UserSession: UserSessionMethodsPOST,
            Supplier: SupplierMethodsPOST,
            Draft: DraftMethodsPOST,
            SaveDraft: SaveDraftMethodsPOST,
            CreatePO: CreatePurchaseOrderMethodsPOST,
            DraftLines: DraftLinesMethodPOST,
            CompanyInfoConfig: CompanyInfoMethodPOST,
            Material: MaterialMethodPOST,
            SalesOrderDraft: SalesOrderDrafMethodtPOST,
            SalesOrder: SalesOrderMethodPOST,
            ValidateSalesOrderMaterial: ValidateSalesOrderMaterialMethodPOST,
            SalesOrderFromDraft: SalesOrderFromDraftMethodPOST,
            SplitOrder: SplitOrderPost,
            Role: CreateRoleMethodsPOST,
            User: UserMethodsPOST,
            Settings: SettingsMethodsPOST,
        };
    }
    get PUT() {
        return {
            CauseCodes: CauseCodesMethodsPUT,
            DedicatedLocation: DedicatedLocationMethodsPUT,
            Inventory: InventoryMethodsPUT,
            Job: JobMethodsPUT,
            Location: LocationMethodsPUT,
            OutgoingOrder: OutgoingOrderMethodsPUT,
            Printer: PrinterMethodsPUT,
            Process: ProcessMethodsPUT,
            Stock: StockMethodsPUT,
            StockAttributeConfiguration: StockAttributeConfigurationMethodsPUT,
            Task: TaskMethodsPUT,
            User: UserMethodsPUT,
            Supplier: SupplierMethodsPUT,
            AssociateProductToSupplier: AssociateProductToSupplierMethodPUT,
            SalesOrderDraft: UpdateSalesOrderDraftMethodPUT,
            Role: UserRoleMethodsPUT,
            UpdatePhoto: UpdatePhotoMethodsPUT,
            UpdateDefaultLanguage: UpdateDefaultLanguagePUT,
            ChangePassword: ChangePasswordMethodsPUT,
            ChangePasswordDuringLogin: ChangePasswordDuringLoginMethodsPUT,
            UpdateDraft: DraftMethodsPUT,
            UpdateMaterial: MaterialMethodsPUT
        };
    }
    get DELETE() {
        return {
            CauseCodes: CauseCodesMethodsDELETE,
            DedicatedLocation: DedicatedLocationMethodsDELETE,
            Location: LocationMethodsDELETE,
            Printer: PrinterMethodsDELETE,
            StockAttributeConfiguration: StockAttributeConfigurationMethodsDELETE,
            User: UserMethodsDELETE,
            Supplier: SupplierMethodsDELETE,
            Draft: DraftMethodsDELETE,
            SalesOrderDraft: SalesOrderDraftMethodsDELETE,
            Role: UserRoleMethodsDELETE
        };
    }
}

export const $webApi = new WebApiService();

class AuthenticationMethodsGET {
    static async GenerateOidcUri(businessUnitName: string, isApportAccount: boolean = false): Promise<IOidcUri> {
        const url = WebApi.GET.Authentication.GenerateOidcUri
            .replace('{businessUnitName}', businessUnitName)
            .replace('{isApportAccount}', isApportAccount.toString());
        const { data } = await Axios.get<IOidcUri>(`${$environment.apiURL}${url}`, {});

        return data;
    }

    static async SignalR(): Promise<string> {
        const { data } = await Axios.get<string>(`${$environment.apiURL}${WebApi.GET.Authentication.SignalR}`, {});

        return data;
    }

    static async CheckOpenIdFeatureEnabled(businessUnitName: string): Promise<string> {
        const { data } = await Axios.get<string>(`${$environment.apiURL}${WebApi.GET.OpenIdFeature.Get
            .replace('{businessUnitName}', businessUnitName)}`, {});

        return data;
    }
}
class AuthenticationMethodsPOST {
    static async User(authCredentials: IAuthCredentials | {}): Promise<IAuthResponse | IApportResponse<null>> {
        const { data } = await Axios.post<IAuthResponse>(`${$environment.apiURL}${WebApi.POST.Authentication.User}`, authCredentials, {});

        return data;
    }
}

class CauseCodesMethodsGET {
    static async Header(): Promise<ICauseCodeHeader[]> {
        const { result } = await $http.get<ICauseCodeHeader[]>(WebApi.GET.CauseCodes.Header, this.Header, true);

        return result;
    }
}
class CauseCodesMethodsPUT {
    static async Create(code: ICauseCode): Promise<IApportExtendedResponse<string>> {
        return $http.put<string>(WebApi.PUT.CauseCodes.Update, code, this.Create.bind(this, code));
    }
    static async Update(code: ICauseCode): Promise<IApportExtendedResponse<string>> {
        return $http.put<string>(WebApi.PUT.CauseCodes.Update, code, this.Update.bind(this, code));
    }
}
class CauseCodesMethodsDELETE {
    static async ById(id: string): Promise<any> {
        return $http.delete<void>(`${WebApi.DELETE.CauseCodes.Remove}/${id}`, this.ById.bind(this, id));
    }
}

class CommandMethodsGET {
    static async TransationStatus(transationId: string): Promise<ICommandTransactionStatus> {
        const { result } = await $http.get<ICommandTransactionStatus>(`${WebApi.GET.Command.TransationStatus}/${transationId}`, null);

        return result;
    }
}

class ContainerMethodsGET {
}

class DedicatedLocationMethodsGET {
    static async All(): Promise<IDedicatedLocationHeader[]> {
        const { result } = await $http.get<IDedicatedLocationHeader[]>(WebApi.GET.DedicatedLocation.All);

        return result;
    }
    static async Single(locationId: string, materialId: string): Promise<IDedicatedLocationHeader> {
        const { result } = await $http.get<IDedicatedLocationHeader>(`${WebApi.GET.DedicatedLocation.Single}?locationId=${locationId}&materialId=${materialId}`);

        return result;
    }
}
class DedicatedLocationMethodsPOST {
    static async Create(command: IDedicatedLocationCommand): Promise<string> {
        const { result } = await $http.post<string>(WebApi.POST.DedicatedLocation.Create, command, this.Create.bind(this, command));

        return result;
    }
}
class DedicatedLocationMethodsPUT {
    static async Update(command: IDedicatedLocationCommand): Promise<string> {
        const { result } = await $http.put<string>(WebApi.PUT.DedicatedLocation.Update, command, this.Update.bind(this, command));

        return result;
    }
}
class DedicatedLocationMethodsDELETE {
    static async Delete(locationId: string, materialId: string): Promise<void> {
        await $http.delete(`${WebApi.DELETE.DedicatedLocation.Delete}?locationId=${locationId}&materialId=${materialId}`, this.Delete.bind(this, locationId, materialId));
    }
}

class ImageMethodsGET {

}

class IngoingOrderMethodsGET {

}
class IngoingOrderMethodsPATCH {
    static async Complete(command: { orderJobRelations: Array<{ orderIds: string[]; }> }): Promise<IApportResponse<IMultiProcessResponse>> {
        return await $http.patch<IMultiProcessResponse>(WebApi.PATCH.IngoingOrder.Complete, command, this.Complete.bind(this, command));
    }
}

class IntegrationMonitorMethodsGET {
    static async TypeFilters(): Promise<string[]> {
        const { result } = await $http.get<string[]>(WebApi.GET.IntegrationMonitor.TypeFilters);

        return result;
    }


    static async ExternalSystemNames(): Promise<string[]> {
        const { result } = await $http.get<string[]>(WebApi.GET.IntegrationMonitor.ExternalSystemNames);

        return result;
    }

    static async ExternalEndpointNames(): Promise<string[]> {
        const { result } = await $http.get<string[]>(WebApi.GET.IntegrationMonitor.ExternalEndpointNames);

        return result;
    }

    static async MainRetrievalEndpointNames(): Promise<string[]> {
        const { result } = await $http.get<string[]>(WebApi.GET.IntegrationMonitor.MainRetrievalEndpointNames);

        return result;
    }

    static async EventSubscriptionNames(): Promise<string[]> {
        const { result } = await $http.get<string[]>(WebApi.GET.IntegrationMonitor.EventSubscriptionNames);

        return result;
    }

    static async SortOrder(): Promise<IApiEnum[]> {
        const { result } = await $http.get<IApiEnum[]>(WebApi.GET.IntegrationMonitor.SortOrder);

        return result;
    }

    static async ResultLevel(): Promise<IApiEnum[]> {
        const { result } = await $http.get<IApiEnum[]>(WebApi.GET.IntegrationMonitor.ResultLevel);

        return result;
    }

    static async InitiatedEnum(): Promise<IApiEnum[]> {
        const { result } = await $http.get<IApiEnum[]>(WebApi.GET.IntegrationMonitor.InitiatedEnum);

        return result;
    }
}

class InstructionSetMethodsPOST {

}

class InventoryMethodsGET {
    static async LineTasks(inventoryLineId: string): Promise<IInventoryLineTask[]> {
        const { result } = await $http.get<IInventoryLineTask[]>(`${WebApi.GET.Inventory.Lines}/${inventoryLineId}/tasks`, this.LineTasks.bind(this, inventoryLineId));

        return result;
    }
}
class InventoryMethodsPUT {
    static async Accept(command: { inventoryLineIds: string[] }): Promise<IApportExtendedResponse<IInventoryAcceptResponse>> {
        return await $http.put<IInventoryAcceptResponse>(WebApi.PUT.Inventory.Accept, command, this.Accept.bind(this, command));
    }

    static async AcceptInventory(command: { inventoryIds: string[] }): Promise<IApportExtendedResponse<IInventoryAcceptResponse>> {
        return await $http.put<IInventoryAcceptResponse>(WebApi.PUT.Inventory.ApproveInventory, command, this.AcceptInventory.bind(this, command));
    }

    static async Cancel(command: { ids: string[] }): Promise<any> {
        return await $http.put<any>(WebApi.PUT.Inventory.Cancel, command, this.Cancel.bind(this, command));
    }

    static async CancelInventory(command: { inventoryIds: string[] }): Promise<any> {
        return await $http.put<any>(WebApi.PUT.Inventory.CancelInventory, command, this.CancelInventory.bind(this, command));
    }
}

class JobMethodsGET {
    static async Instructions(jobId: string): Promise<IJobInstructionResponse> {
        const { result } = await $http.get<IJobInstructionResponse>(`${WebApi.GET.Job.Instructions}/${jobId}`);

        return result;
    }
}
class JobMethodsPOST {

    // TODO This endpoint does not exist in the WebApi project. Should we just delete this method entirely?
    // verify Maintenance usage
    static async CreateAndAssignJobFromTasks(command: { taskIds: string[]; userId?: string; userRoleId?: string }): Promise<any> {
        const { result } = await $http.post(WebApi.POST.Job.CreateAndAssignJobFromTasks, command, this.CreateAndAssignJobFromTasks.bind(this, command));

        return result;
    }

    static async CreateAndStartFromStocks(command: { stockIds: string[]; processDefinitionCategory: ProcessDefinitionCategory; }): Promise<IApportResponse<IJobInstructionResponse>> {
        return await $http.post<IJobInstructionResponse>(WebApi.POST.Job.CreateAndStartFromStocks, command, null);
    }

    static async CreateInventoryJobs(command: { stockIds: string[] }): Promise<IApportResponse<string[]>> {
        return await $http.post<string[]>(WebApi.POST.Job.CreateInventoryJobs, command, this.CreateInventoryJobs.bind(this, command));
    }

    static async CreateRecountInventoryJobs(command: {
        inventoryLineIds: string[];
        maxTasksPerJob: number;
        userId: string;
        userRoleId: string;
    }): Promise<IApportResponse<string[]>> {
        return await $http.post<any>(
            WebApi.POST.Job.CreateRecountInventoryJobs,
            command,
            this.CreateRecountInventoryJobs.bind(this, command)
        );
    }
}
class JobMethodsPUT {
    static async Assign(command: { jobIds: string[]; userId: string; }): Promise<IApportResponse<string[]> | IMultiProcessResponse> {
        return await $http.put<any>(WebApi.PUT.Job.Assign, command, this.Assign.bind(this, command));
    }

    static async AssignOperationsToUser(command: { userId: string; operations: string[]; }): Promise<IApportResponse<IMultiJobResponse>> {
        return await $http.put<IMultiJobResponse>(WebApi.PUT.Job.AssignOperationsToUser, command, this.AssignOperationsToUser.bind(this, command));
    }

    static async End(command: { jobId: string }): Promise<IApportResponse<void>> {
        return await $http.put(WebApi.PUT.Job.End, command, this.End.bind(this, command));
    }

    static async TryDeviate(command: { jobId: string; instructionResponses: Array<{ instructionId: string; response: string | number; }> }): Promise<IApportResponse<void>> {
        return await $http.put<void>(WebApi.PUT.Job.TryDeviate, command, this.TryDeviate.bind(this, command), undefined, true, false);
    }
    static async TryFinish(command: { jobId: string, instructionResponses: Array<{ instructionId: string; response: string | number }> }): Promise<IApportResponse<IViewCommandResult>> {
        return await $http.put<IViewCommandResult>(WebApi.PUT.Job.TryFinish, command, this.TryFinish.bind(this, command));
    }

    static async UnAssign(command: { jobId: string; }): Promise<IApportResponse<IUnassignResponse>> {
        return await $http.put<IUnassignResponse>(WebApi.PUT.Job.UnAssign, command, this.UnAssign.bind(this, command));
    }
}

class LoadCarrierTypeMethodsGET {
    static async Header(): Promise<ILoadCarrierType[]> {
        const { result } = await $http.get<ILoadCarrierType[]>(WebApi.GET.LoadCarrierType.Header, null, true);

        return result;
    }
}

class LocalizationMethodsGET {
    static async Languages(): Promise<string[]> {
        const { result } = await $http.get<string[]>(WebApi.GET.Localization.Languages);

        return result;
    }

    static async Translations(version: string, langCode: string): Promise<string> {
        const { result } = await $http.get<string>(`${WebApi.GET.Localization.Translations}&clientVersion=${version}&languageCode=${langCode}`);

        return result;
    }
}

class LocationMethodsGET {

    static async Header(name?: string): Promise<ILocationHeader[]> {
        const suffix = !!name ? `?name=${name}` : '';

        const { result } = await $http.get<ILocationHeader[]>(`${WebApi.GET.Location.Header}${suffix}`, this.Header.bind(this, name));

        return result;
    }
    static async HeaderById(id: string): Promise<ILocationHeader> {
        const { result } = await $http.get<ILocationHeader>(`${WebApi.GET.Location.Header}/${id}`, this.HeaderById.bind(this, id));

        return result;
    }
}
class ErpClassificationMethodsGET {
    static async Header(): Promise<IErpClassification[]> {

        const { result } = await $http.get<IErpClassification[]>(`${WebApi.GET.ErpClassification.Header}`);

        return result;
    }
}
class LocationMethodsPOST {
    static async Create(command: ILocation): Promise<any> {
        return $http.post<any>(WebApi.POST.Location.Create, command, this.Create.bind(this, command));
    }
}
class LocationMethodsPUT {
    static async Update(command: ILocation): Promise<any> {
        return $http.put<any>(WebApi.PUT.Location.Update, command, this.Update.bind(this, command));
    }
}
class LocationMethodsDELETE {
    static async ByIds(ids: string[]): Promise<IApportExtendedResponse<IMultiProcessResponse>> {
        const suffix: string = ids.reduce((sum, id) => {
            return sum + `ids=${id}&`;
        }, '?');

        return $http.delete<IMultiProcessResponse>(`${WebApi.DELETE.Location.ById}${suffix.substring(0, suffix.length - 1)}`, this.ByIds.bind(this, ids));
    }
}

class LocationRoleMethodsGET {
    static async Header(): Promise<ILocationRole[]> {
        const { result } = await $http.get<ILocationRole[]>(WebApi.GET.LocationRole.Header, null, true);

        return result;
    }
}
class LocationRoleGroupMethodsGET {
    static async Header(): Promise<ILocationRoleGroup[]> {
        const { result } = await $http.get<ILocationRoleGroup[]>(WebApi.GET.LocationRoleGroup.Header, null, true);

        return result;
    }

    static async Detail(): Promise<any[]> {
        const { result } = await $http.get<any[]>(WebApi.GET.LocationRoleGroup.Detail, null, true);

        return result;
    }
}

class LookupMentodsGet {
    static async All() {}

    static async ByType(lookupType: LookupType): Promise<LookupResponse> {
        const url = WebApi.GET.Lookup.ByType
            .replace('{lookupType}', lookupType);

        const { result } = await $http.get<any>(url, null, true);

        return result;
    }
}


class MaterialMethodsGET {
    static cache = new Map();
    
    static async BOM(materialNumber: string): Promise<IMaterialBOM[]> {
        const url = WebApi.GET.Material.BOM
            .replace('{materialNumber}', materialNumber);

        const { result } = await $http.get<IMaterialBOM[]>(url, null, true);

        return result;
    }

    static async Search(query: string): Promise<IServerSideResponse<IMaterialDescription[]>> {
        if (this.cache.has(query)) {
            // note: it seems that using ES is causing grid to keep reloading data when endpoint is called (and url is not using those params)
            // this workaround should prevent it if nothing has changed... to be investigated
            return this.cache.get(query);
        }
        this.cache.clear();
        const url = `${WebApi.GET.Material.Search}${query}`;

        const { result, totalCount } = await $http.get<IMaterialDescription[]>(url, this.Search.bind(this), true);

        this.cache.set(query, {
            data: result,
            totalCount
        });

        return {
            data: result,
            totalCount
        };
    }
}

class OutgoingOrderMethodsPOST {
    static async CancelSalesOrder(command: { orderNo: string; pickList: string; }): Promise<void> {
        await $http.post(WebApi.POST.OutgoingOrder.CancelSalesOrder, command, this.CancelSalesOrder.bind(this, command));
    }
}
class OutgoingOrderMethodsPUT {
    static async OrderlineQuantity(command: { orderNo: string; orderLineNo: string; quantity: number; pickList: string; }): Promise<void> {
        await $http.put(WebApi.PUT.OutgoingOrder.OrderlineQuantity, command, this.OrderlineQuantity.bind(this, command));
    }

    // This endpoint does not seem to exist in OutgoingOrderController.cs
    static async PickedStock(command: { orderNo: string; pickList: string; }): Promise<any> {
        return await $http.put(WebApi.PUT.OutgoingOrder.PickedStock, command, this.PickedStock.bind(this, command));
    }
    static async UnprepareStock(id: string | number): Promise<any> {
        return await $http.put(`${WebApi.PUT.OutgoingOrder.UnPrepaareStock}/${id}`, null, null, {}, false);
    }
    
    static async UpdateDeliveryPriority(command: IOrderDeliveryCommand): Promise<any> {
        return await $http.put(`${WebApi.PUT.OutgoingOrder.DeliveryPriorityDate}`, command, null, {}, false);
    }
}

class ProcessMethodsPUT {
    static async CancelOrderInProcesses(command: { processIds: string[] }): Promise<IApportExtendedResponse<IMultiProcessResponse>> {
        return $http.put(WebApi.PUT.Process.CancelOrderInProcesses, command, this.CancelOrderInProcesses.bind(this, command));
    }

    static async ExecutionPriority(command: {
        processesIds: string[];
        executionPriority: {
            value: number;
            date: Date;
        };
    }): Promise<void> {
        await $http.put(WebApi.PUT.Process.ExecutionPriority, command, this.ExecutionPriority.bind(this, command));
    }

    static async Prepare(command: { processIds: string[], allowPartialPreparation: boolean, waitForPreparation: boolean }): Promise<IApportExtendedResponse<IMultiProcessResponse>> {
        return $http.put(WebApi.PUT.Process.Prepare, command, this.Prepare.bind(this, command));
    }

    static async UnPrepare(command: { processIds: string[] }): Promise<IApportExtendedResponse<IMultiProcessResponse>> {
        return $http.put(WebApi.PUT.Process.UnPrepare, command, this.UnPrepare.bind(this, command));
    }
}

class PrinterMethodsGET {
    static async Description(): Promise<IPrinterDescription[]> {
        // note: backend is not returning IApportResponse, but an array
        const result: any = await $http.get(WebApi.GET.Printer.Description);

        return result as IPrinterDescription[];
    }
}
class PrinterMethodsPOST {
    static async Create(printer: IPrinter): Promise<IApportResponse<string>> {
        return await $http.post<string>(WebApi.POST.Printer.Create, printer, this.Create.bind(this, printer));
    }
}
class PrinterMethodsPUT {
    static async Update(printer: IPrinter): Promise<void> {
        await $http.put(WebApi.PUT.Printer.Update, printer, this.Update.bind(this, printer));
    }
}
class PrinterMethodsDELETE {
    static async ById(id: string): Promise<void> {
        await $http.delete(`${WebApi.DELETE.Printer.ById}/${id}`, this.ById.bind(this, id));
    }
}

class ProcessDefinitionMethodsGET {
    static async ActiveProcessDefinitionCategories(): Promise<ProcessDefinitionCategoryName[]> {
        const { result } = await $http.get(WebApi.GET.ProcessDefinition.ActiveProcessDefinitionCategories);
        
        return result as ProcessDefinitionCategoryName[];
    }
}

class ReportMethodsGET {
    static async All(): Promise<IKpiData[]> {
        const { result } = await $http.get<IKpiData[]>(WebApi.GET.Report.All);

        return result;
    }

    static async MaterialSummary(): Promise<IMaterialSummaryReportData> {
        const { result } = await $http.get<IMaterialSummaryReportData>(WebApi.GET.Report.MaterialSummary, null, true);

        return result;
    }

    static async MaterialTransactions(params: IMaterialTransationsRequestData): Promise<IMaterialTransationsReportData[]> {
        const request = UrlHelper.create(WebApi.GET.Report.MaterialTransactions);
        const model = {
            dateFrom: params.dateFrom,
            dateTo: params.dateTo,
            fromLocation: params.fromLocation,
            toLocation: params.toLocation,
            processTypes: params.processTypes,
            materialsSKU: params.materialsSKU,
            skip: params.skip,
            take: params.take
        }

        const { result } = await $http.post<IMaterialTransationsReportData[]>(request.toUrl(), model, this.MaterialTransactions.bind(this));

        return result
    }


    static async GetLocationRoles(params?: any): Promise<any> {
        const request = UrlHelper.create(WebApi.GET.LocationRoles.Get);
        const { result } = await $http.get<any>(request.toUrl());

        return result
    }

    static async SlowMovingStock(params: ISlowMovingStockRequestData): Promise<ISlowMovingStockReportData[]> {
        const request = UrlHelper.create(WebApi.GET.Report.SlowMovingStock);
        if (params.dateFrom) {
            request.setParam('fromDate', params.dateFrom);
        }
        if (params.dateTo) {
            request.setParam('toDate', params.dateTo);
        }
        if (params.materialName) {
            request.setParam('materialName', params.materialName);
        }
        if (params.pickingFrequencyFrm) {
            request.setParam('minPickingFrequency', params.pickingFrequencyFrm);
        }
        if (params.pickingFrequencyTo) {
            request.setParam('maxPickingFrequency', params.pickingFrequencyTo);
        }
        if (params.locationNames) {
            params.locationNames.forEach((type:string) => {
                request.addParam('locationRoles', type);
            });
            // request.setParam('locationRoles', params.locationNames.join(','));

        }

        if (params.itemNo) {
            request.setParam('itemNumber', params.itemNo);
        }
        if (params.skip) {
            request.setParam('skip', params.skip);
        }
        if (params.take) {
            request.setParam('take', params.take);
        }
        const result:any = await $http.get<ISlowMovingStockReportData[]>(request.toUrl(), null, true);
        return result
    }
}

class SeedingMethodsPOST {
    static async Excel(formData: FormData): Promise<string> {
        const { result } = await $http.post<string>(
            WebApi.POST.Seeding.Excel,
            formData,
            this.Excel.bind(this, formData),
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        return result;
    }

    static async Grid_BusinessUnitSettings(apiViewName: string, stateStr: string): Promise<void> {
        await $http.post(WebApi.POST.Seeding.Grid_BusinessUnitSettings, [{
            key: apiViewName,
            value: stateStr
        }], null);
    }

    static async Grid_FactorySettings(apiViewName: string, stateStr: string): Promise<void> {
        await $http.post(WebApi.POST.Seeding.Grid_FactorySettings, [{
            key: apiViewName,
            value: stateStr
        }], null);
    }

    static async KPI_BusinessUnitSettings(settings: string): Promise<void> {
        await $http.post(WebApi.POST.Seeding.KPI_BusinessUnitSettings, [{
            key: 'Home',
            value: settings
        }], null);
    }

    static async KPI_FactorySettings(settings: string): Promise<void> {
        await $http.post(WebApi.POST.Seeding.KPI_FactorySettings, [{
            key: 'Home',
            value: settings
        }], null);
    }

    static async Grid_ScopeUnitSettings(apiViewName: string, customName: string, stateStr: string, scope: string): Promise<void> {
        await $http.post(WebApi.POST.Seeding.Grid_ScopeUnitSettings
            .replace('{scope}', scope)
            .replace('{owner}', apiViewName), [{
                key: customName,
                value: stateStr
            }], null);
    }

    static async UserSettings(command: Array<{ key: string, value: any }>, owner: string = 'userSettings', clientId: ApportClientId = 'Cockpit'): Promise<void> {
        const url = WebApi.POST.Seeding.CockpitUserSettings
            .replace('{owner}', owner)
            .replace('{clientId}', clientId);

        await $http.post(url, command, this.UserSettings.bind(this, command));
    }

    static async Grid_UserSettings(apiViewName: string, stateStr: string): Promise<void> {
        await $http.post(WebApi.POST.Seeding.Grid_UserSettings, [{
            key: apiViewName,
            value: stateStr
        }], null);
    }
}

class StockMethodsGET {
    static async OutgoingReservationsById(id: string): Promise<ITaskReservingStock[]> {
        const { result } = await $http.get<ITaskReservingStock[]>(`${WebApi.GET.Stock.OutgoingReservationsById}/${id}`);

        return result;
    }
}

class StockMethodsPUT {

}

class StockAttributeConfigurationMethodsGET {
    static async Description(): Promise<IStockAttributeDescription[]> {
        const { result } = await $http.get<IStockAttributeDescription[]>(WebApi.GET.StockAttributeConfiguration.Description);

        return result;
    }

    static async EnumOptions(): Promise<IStockAttributeEnumOptions> {
        const { result } = await $http.get<IStockAttributeEnumOptions>(WebApi.GET.StockAttributeConfiguration.EnumOptions);

        return result;
    }
}

class StockAttributeConfigurationMethodsPUT {
    static async Create(obj: IStockAttributeBase): Promise<IApportResponse<string>> {
        return await $http.put<string>(WebApi.PUT.StockAttributeConfiguration.Create, obj, this.Create.bind(this, obj));
    }

    static async Update(obj: IStockAttributeBase): Promise<IApportResponse<string>> {
        return await $http.put<string>(WebApi.PUT.StockAttributeConfiguration.Update, obj, this.Update.bind(this, obj));
    }
}

class StockAttributeConfigurationMethodsDELETE {
    static async ById(id: string): Promise<void> {
        const url = WebApi.DELETE.StockAttributeConfiguration.ById.replace('{id}', id);

        await $http.delete(url, this.ById.bind(this, id));
    }
}
class StorageMethodsGET {

}

class TaskMethodsGET {

}

class TaskMethodsPUT {
    static async Cancel(command: { taskIds: string[]; }): Promise<IApportExtendedResponse<IMultiProcessResponse>> {
        return await $http.put<IMultiProcessResponse>(WebApi.PUT.Task.Cancel, command, this.Cancel.bind(this, command));
    }
}

class ToolsMethodsPOST {
    static async UpdateOperationViewData(): Promise<void> {
        await $http.post(WebApi.POST.Tools.UpdateOperationViewData, {}, this.UpdateOperationViewData.bind(this));
    }
}

class UserMethodsGET {
    static async Active(): Promise<IActiveUsers> {
        const { result } = await $http.get<IActiveUsers>(WebApi.GET.User.Active);

        return result;
    }

    static async Detail(): Promise<IUser[]> {
        const { result } = await $http.get<IUser[]>(WebApi.GET.User.Detail,null, true);

        return result;
    }
    static async Description(): Promise<IUser[]> {
        const { result } = await $http.get<IUser[]>(WebApi.GET.User.Description,null, true);

        return result;
    }
    static async Summary(): Promise<IUserSummary[]> {
        const { result } = await $http.get<IUserSummary[]>(WebApi.GET.User.Summary ,null, true);

        return result;
    }

    static async TerminalSettingsAll() {
        const { result } = await $http.get<ITerminalSettingsResponse>(WebApi.GET.User.TerminalSettings);

        return result;
    }

    static async SystemSettingsAll() {
        const { result } = await $http.get<ISystemSettingsResponse>(WebApi.GET.User.SystemSettings);

        return result;
    }

    static async CockpitSettingsAll() {
        const { result } = await $http.get<ICockpitSettingsResponse>(WebApi.GET.User.CockpitSettings);

        return result;
    }

    static async CockpitSettingsFor(owner: string): Promise<ISettings> {
        const { result } = await $http.get<ISettings>(`${WebApi.GET.User.CockpitSettings}&owner=${owner}`);
        return result;
    }

    static async GridSettingsAll(): Promise<ISettings> {
        const { result } = await $http.get<ISettings>(`${WebApi.GET.User.GridSettings}`);

        return result;
    }

    static async GridSettingsFor(apiViewName: string): Promise<ISettings> {
        const { result } = await $http.get<ISettings>(`${WebApi.GET.User.GridSettings}&key=${apiViewName}`);

        return result;
    }

    // TODO 
    static async HistoryPopupSettings() {
        const { result } = await $http.get(WebApi.GET.User.HistoryPopupSettings);

        return result;
    }

    static async MenuSettings(): Promise<IMenuSettings> {
        const { result } = await $http.get<IMenuSettingsResponse>(WebApi.GET.User.MenuSettings);

        return result.menuSettings;
    }

    static async RoutesSettings(): Promise<any> {
        const { result } = await $http.get<IMenuSettingsResponse>(WebApi.GET.User.RoutesSettings);
        
        if (!result || !result.menuSettings || !result.menuSettings.routeConfigs) {
            return {};
        }

        let configs = [];
        let { routeConfigs, reportConfigs } = result.menuSettings;

        try {
            configs = JSON.parse(routeConfigs);
            reportConfigs = JSON.parse(reportConfigs!)
        } catch (error) {
            console.error('Failed to parse settings', routeConfigs);
        }

        return { config:configs, reportConfig: reportConfigs};
    }

    // TODO
    static async ReservationPopupSettings() {
        const { result } = await $http.get(WebApi.GET.User.ReservationPopupSettings);

        return result;
    }

    static async SignalRSettings(): Promise<ISignalRResponse> {
        const { result } = await $http.get<ISignalRResponse>(WebApi.GET.User.SignalRSettings);

        return result;
    }

}
class UserMethodsPUT {
    static async Settings(clientId: ApportClientId, owner: string, key: string, value: string): Promise<void> {
        await $http.put(WebApi.PUT.User.Settings, {
            clientId,
            owner,
            key,
            value
        }, null);
    }
    static async CockpitSettings(owner: string, key: string, value: any): Promise<void> {
        await $http.put(WebApi.PUT.User.Settings, {
            clientId: 'Cockpit',
            owner,
            key,
            value
        }, null);
    }

    static async CockpitSettingsForAll(owner: string, key: string, value: any): Promise<void> {
        await $http.put(WebApi.PUT.User.SettingsForAll, {
            clientId: 'Cockpit',
            owner,
            key,
            value
        }, null);
    }

    static async Update(command: any): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.User.Update, command, this.Update.bind(this, command));
    }

    static async Deactivate(command: any): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.User.Deactivate, command, this.Update.bind(this, command));
    }
}
class UserMethodsDELETE {
    static async Settings(owner: string, key: string, value: any): Promise<void> {
        await $http.delete(WebApi.DELETE.User.Settings, null, {
            clientId: 'Cockpit',
            owner,
            key,
            value
        });
    }

    static async Delete(ids: string): Promise<IApportResponse<IMultiProcessResponse>> {
        return $http.delete<IMultiProcessResponse>(`${WebApi.DELETE.Users.Delete}/${ids}`, this.Delete.bind(this, ids));
    }
}

class UserChoiceMethodsPOST {
    static async Perform(command: { userChoiceId: string, userOptionId: string }): Promise<void> {
        await $http.post<any>(WebApi.POST.UserChoice.Perform,
            command,
            this.Perform.bind(this, command)
        );
    }
}

class UserRoleMethodsGET {
    static async Header(): Promise<IUserRole[]> {
        const { result } = await $http.get<IUserRole[]>(WebApi.GET.UserRole.Header);

        return result;
    }

    static async Detail(): Promise<IUserRole[]> {
        const { result } = await $http.get<IUserRole[]>(WebApi.GET.UserRole.Detail, null, true);

        return result;
    }
    static async Description(): Promise<IUserRole[]> {
        const { result } = await $http.get<IUserRole[]>(WebApi.GET.UserRole.Description, null, true);

        return result;
    }

    static async UserAssignableRolesHeader(): Promise<IUserRole[]> {
        const { result } = await $http.get<IUserRole[]>(WebApi.GET.UserRole.UserAssignableRolesHeader);

        return result;
    }

    static async UserAssignableRolesDescription(): Promise<IUserRole[]> {
        const { result } = await $http.get<IUserRole[]>(WebApi.GET.UserRole.UserAssignableRolesDescription);

        return result;
    }

    static async UserAssignableRolesDetail(): Promise<IUserRole[]> {
        const { result } = await $http.get<IUserRole[]>(WebApi.GET.UserRole.UserAssignableRolesDetail);

        return result;
    }
}

class UserSessionMethodsGET {

}
class UserSessionMethodsPOST {
    static async HeartBeat(): Promise<void> {
        await $http.post<void>(WebApi.POST.UserSession.HeartBeat, null, null, undefined, false, false);
    }
}

class SupplierMethodsPOST {
    static async Create(command: ISupplier): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.Supplier.Create, command, this.Create.bind(this, command));
    }
}

class DraftMethodsPOST {
    static async Create(command: IDraftSearchState): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.Draft.Get, command, this.Create.bind(this, command));
    }
}

class SupplierMethodsGET {
    static async Header(): Promise<ISupplier[]> {
        const { result } = await $http.get<ISupplier[]>(WebApi.GET.Supplier.Header, null, true);

        return result;
    }
}

class SupplierMethodsPUT {
    static async Update(command: ISupplier): Promise<IApportExtendedResponse<string>> {
        return $http.put<string>(WebApi.PUT.Supplier.Update, command, this.Update.bind(this, command));
    }
}

class SupplierMethodsDELETE {
    static async Delete(ids: string[]): Promise<IApportExtendedResponse<IMultiProcessResponse>> {
        const suffix: string = ids.reduce((sum, id) => {
            return sum + `ids=${id}&`;
        }, '?');
        return $http.delete<IMultiProcessResponse>(`${WebApi.DELETE.Supplier.Delete}${suffix.substring(0, suffix.length - 1)}`, this.Delete.bind(this, ids));
    }
}

class AssociateProductToSupplierMethodPUT {
    static async Update(command: IAssociateProduct): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.Supplier.AssociateMaterialsToSupplier, command, this.Update.bind(this, command));
    }
}

class GetProductLineForSupplierMethodPOST {
    static async Get(param:any, command:any): Promise<any> {
        const  result = await $http.post<IProduct[]>(`${WebApi.POST.ProductLineForSupplier.Product}/${param}`,command,this.Get.bind(this,command),undefined, true, true);
        return result;
    }
}

class GetDraftSupplierMethodGET {
    static async Get(): Promise<ISupplier[]> {
        const { result } = await $http.get<ISupplier[]>(WebApi.GET.Draft.Supplier, null, true);
        return result;
    }
}

class GetDraftNumberMethodGET {
    static async Get(): Promise<string> {
        const { result } = await $http.get<string>(WebApi.GET.Draft.GetDraftNumber, null, true);
        return result;
    }
}

class GetDraftLinesMethodGET {
    static async Get(command: any, queryString: string): Promise<IPurchaseDraftResponse[]> {
        const { result } = await $http.get<IPurchaseDraftResponse[]>(`${WebApi.GET.Draft.GetDraftLines}${queryString}`, null, true);
        return result;
    }
}
class SaveDraftMethodsPOST {
    static async Create(command: any): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.Draft.Create, command, this.Create.bind(this, command), undefined, false, false);
    }
}

class DraftMethodsPUT {
    static async Update(command: any): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.UpdateDraft.Update, command, this.Update.bind(this, command), undefined, false, false);
    }
}

class GetDraftLinesByIdMethodGET {
    static async Get(id: string): Promise<IPurchaseDraftResponse[]> {
        const { result } = await $http.get<IPurchaseDraftResponse[]>(`${WebApi.GET.Draft.Detail}${id}`, null, true);
        return result;
    }
}

class DraftMethodsDELETE {
    static async Delete(ids: string[]): Promise<IApportResponse<IMultiProcessResponse>> {
        const suffix: string = ids.reduce((sum, id) => {
            return sum + `ids=${id}&`;
        }, '?');
        return $http.delete<IMultiProcessResponse>(`${WebApi.DELETE.Draft.Delete}${suffix.substring(0, suffix.length - 1)}`, this.Delete.bind(this, ids));
    }
}

class MaterialMethodsPUT {
    static async Update(command: any): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.UpdateMaterial.Update, command, this.Update.bind(this, command));
    }
}

class SplitOrderPost {
    static async Update(command: any): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.SplitOrder.Update, command, this.Update.bind(this, command));
    }
}

class CreatePurchaseOrderMethodsPOST {
    static async Create(command: any): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.Draft.CreatePurchaseOrder, command, this.Create.bind(this, command), undefined, false, false);
    }
}

class GetOrderNumberMethodGET {
    static async Get(): Promise<string> {
        const { result } = await $http.get<string>(WebApi.GET.Draft.GetOrderNumber, null, true);
        return result;
    }
}

class UnDraftedSupplierMaterialMethodGET {
    static async Get(supplierId: string, draftId: string): Promise<IProduct[]> {
        const { result } = await $http.get<IProduct[]>(`${WebApi.GET.Material.UndraftedSupplierMaterials}/${supplierId}/${draftId}`, null, true);
        return result;
    }
}

class DraftLinesMethodPOST {
    static async Create(command: string[] | null, queryString: string): Promise<IPurchaseDraftResponse[]> {
        const { result } = await $http.post<IPurchaseDraftResponse[]>(`${WebApi.GET.Draft.GetDraftLines}${queryString}`, command, this.Create.bind(this, command), undefined, true, false);
        return result;
    }
}

class CompanyInfoMethodPOST {
    static async Create(command: object | null): Promise<IPurchaseDraftResponse[]> {
        const { result } = await $http.post<IPurchaseDraftResponse[]>(`${WebApi.POST.CompanyInfo.Post}`, command, this.Create.bind(this, command), undefined, true, false);
        return result;
    }
}
    
class SalesOrderDraftGET {
    static async Get(): Promise<string> {
        const { result } = await $http.get<string>(WebApi.GET.SalesOrderDraft.SODraftNumber, null, true);
        return result;
    }
}
class SalesOrderGET {
    static async Get(): Promise<string> {
        const { result } = await $http.get<string>(WebApi.GET.SalesOrderDraft.SalesOrderNumber, null, true);
        return result;
    }
}

class MaterialMethodPOST {
    static async Get(command: any): Promise<any> {
        return $http.post<any>(WebApi.POST.Material.Get, command, this.Get.bind(this, command), undefined, false, false);
    }
}

class OrderTypeGET {
    static async Get(): Promise<IOrderType[]> {
        const { result } = await $http.get<IOrderType[]>(WebApi.GET.OutgoingOrder.OrderType, null, true);
        return result;
    }
}

class DeliveryTypeGET {
    static async Get(): Promise<IDeliveryType[]> {
        const { result } = await $http.get<IDeliveryType[]>(WebApi.GET.OutgoingOrder.DeliveryType, null, true);
        return result;
    }
}

class CustomerInformationGET {
    static async Get(customerName: string): Promise<ICustomerInformation> {
        const { result } = await $http.get<ICustomerInformation>(`${WebApi.GET.OutgoingOrder.CustomerInfomation}/${customerName}`, null, true);
        return result;
    }
}

class SalesOrderDraftByIdMethodGET {
    static async Get(id: string): Promise<ISalesOrderState> {
        const { result } = await $http.get<ISalesOrderState>(`${WebApi.GET.SalesOrderDraft.SODraftById}/${id}`, null, true);
        return result;
    }
}

class SalesOrderDrafMethodtPOST {
    static async Create(command: any): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.SalesOrderDraft.Create, command, this.Create.bind(this, command), undefined, true, true);
    }
}

class UpdateSalesOrderDraftMethodPUT {
    static async Update(command: any): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.SalesOrderDraft.Update, command, this.Update.bind(this, command), undefined, true, true);
    }
}


class SalesOrderMethodPOST {
    static async Create(command: any): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.SalesOrder.Create, command, this.Create.bind(this, command), undefined, true, true);
    }
}

class SalesOrderFromDraftMethodPOST {
    static async Create(command: any): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.SalesOrder.Update, command, this.Create.bind(this, command), undefined, true, true);
    }
}

class SalesOrderDraftMethodsDELETE {
    static async Delete(ids: string[]): Promise<IApportResponse<IMultiProcessResponse>> {
        const suffix: string = ids.reduce((sum, id) => {
            return sum + `ids=${id}&`;
        }, '?');
        return $http.delete<IMultiProcessResponse>(`${WebApi.DELETE.SalesOrderDraft.Delete}${suffix.substring(0, suffix.length - 1)}`, this.Delete.bind(this, ids));
    }
}

class ValidateSalesOrderMaterialMethodPOST {
    static async Create(command: any): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.SalesOrder.Validate, command, this.Create.bind(this, command), undefined, true, true);
    }
}

class CreateRoleMethodsPOST {
    static async Create(command: any): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.CreateRole.Create, command, this.Create.bind(this, command), undefined, true, true);
    }
}

class UserRoleMethodsDELETE {
    static async Delete(ids: string): Promise<IApportResponse<IMultiProcessResponse>> {
        return $http.delete<IMultiProcessResponse>(`${WebApi.DELETE.Role.Delete}/${ids}`, this.Delete.bind(this, ids));
    }
}

class UserRoleMethodsPUT {
    static async Update(command: any): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.UpdateRole.Update, command, this.Update.bind(this, command));
    }
}

class UpdatePhotoMethodsPUT {
    static async Update(command: any): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.User.UpdatePicture, command, this.Update.bind(this, command), undefined, true, true);
    }
}

class UpdateDefaultLanguagePUT {
    static async Update(command: any): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.User.ChangeDefaultLanguage, command, this.Update.bind(this, command), undefined, true, true);
    }
}

class ChangePasswordMethodsPUT {
    static async Update(command: any, onUserChoiceCancel?: () => void): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.User.ChangePassword, command, this.Update.bind(this, command, onUserChoiceCancel), undefined, true, true, onUserChoiceCancel);
    }
}

class ChangePasswordDuringLoginMethodsPUT {
    static async Update(command: any, onUserChoiceCancel?: () => void): Promise<IApportResponse<string>> {
        return $http.put<string>(WebApi.PUT.User.ChangePasswordDuringLogin, command, this.Update.bind(this, command, onUserChoiceCancel), undefined, true, true, onUserChoiceCancel);
    }
}

class UserMethodsPOST {
    static async Create(command: any): Promise<IApportResponse<string>> {
        return $http.post<string>(WebApi.POST.User.Create, command, this.Create.bind(this, command), undefined, true, true);
    }

    static async ResetPassword(userId: string): Promise<IUserPasswordResetResponse> {
        const { result } = await $http.post<IUserPasswordResetResponse>(`${WebApi.POST.User.ResetPassword}?userId=${userId}`, null, null, undefined, false, false);
        return result;
    }
}

class SettingsMethodsGET {
    static async GetAvailableCodeSwitches(): Promise<string[]> {
        const { result } = await $http.get<string[]>(WebApi.GET.Settings.AvailableCodeSwitches);

        return result;
    }

    static async GetEnabledCodeSwitches() : Promise<string[]> {
        const { result } = await $http.get<string[]>(WebApi.GET.Settings.EnabledCodeSwitches);

        return result;
    }

    static async GetAvailableFeatureFlags(): Promise<string[]> {
        const { result } = await $http.get<string[]>(WebApi.GET.Settings.AvailableFeatureFlags);

        return result;
    }

    static async GetEnabledFeatureFlags(): Promise<string[]> {
        const { result } = await $http.get<string[]>(WebApi.GET.Settings.EnabledFeatureFlags);

        return result;
    }
}

class SettingsMethodsPOST {
    static async EnabledCodeSwitches(codeSwitches: string[]) {
        await $http.post<void>(WebApi.POST.Settings.EnabledCodeSwitches, codeSwitches, this.EnabledCodeSwitches.bind(this, codeSwitches));
    }

    static async EnabledFeatureFlags(featureFlags: string[]) {
        await $http.post<void>(WebApi.POST.Settings.EnabledFeatureFlags, featureFlags, this.EnabledFeatureFlags.bind(this, featureFlags));
    }
}